import { createStore } from 'vuex'
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, updateProfile, updatePassword, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, set, onValue, push, update, get} from "firebase/database";
import { DateTime } from "luxon";

interface BlacklistItem {
  code: string;
  cone: number;
  created_at: string;
  description: string;
  entry: string;
  family: string;
  foot: string;
  levelName: string;
  metadata: string;
  method: string;
  name: string;
  side: string;
  version: string;
  permanentlyHidden: boolean;
}


const store = createStore({
  state: {
    user: {
      loggedIn: false,
      data: null,
      uid: '',
    },
    combos: 0,
    myCombos: [],
    blacklist: [],
    myLab: [],
    lab: 0,
    codes: null,
    level: null,
    role: '',
    userList: [],
    userDetail : {},
    isActive: null
  },
  getters: {
    getUser(state) {
      return state.user
    },
    getCombos(state) {
      return state.combos
    },
    getBlacklist(state) {
      return state.blacklist
    },
    getMyCombos(state) {
      return state.myCombos
    },
    getLevel(state) {
      return state.level
    },
    getRole(state) {
      return state.role
    },
    getCodes(state) {
      return state.codes
    },
    getUid(state) {
      return state.user.uid
    },
    getUserList(state) {
      return state.userList
    },
    getUserDetail(state) {
      return state.userDetail
    },
    getIsActive(state) {
      return state.isActive
    },
    getMyLab(state) {
      return state.myLab
    },
    getLab(state) {
      return state.lab
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
      if (data) {
        state.user.uid = data.uid;
      }
    },
    SET_COMBOS(state, data) {
      state.combos = data;
    },
    SET_MY_COMBOS(state, data) {
      state.myCombos = data;
    },
    SET_BLACKLIST(state, data) {
      state.blacklist = data;
    },
    SET_LEVEL(state, data) {
      state.level = data;
    },
    SET_ROLE(state, data) {
      state.role = data;
    },
    SET_CODES(state, data) {
      state.codes = data;
    },
    SET_USERS_DATA(state, data) {
      state.userList = data;
    },
    SET_USER_DETAIL(state, data) {
      state.userDetail = data;
    },
    SET_IS_ACTIVE(state, data) {
      console.log('SET IS ACTIVE', data)
      state.isActive = data;
    },
    SET_LAB(state, data) {
      state.lab = data;
    },
    SET_MY_LAB(state, data) {
      state.myLab = data;
    },
  },
  actions: {

    initAuthState(context) {
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          context.commit('SET_LOGGED_IN', true);
          context.commit('SET_USER', user);  // Establece el usuario en el estado de Vuex
        } else {
          context.commit('SET_LOGGED_IN', false);
          context.commit('SET_USER', null);  // Borra el estado del usuario si no está autenticado
        }
      });
    },
  

    async register(context, { email, password, name }) {
      const response = await createUserWithEmailAndPassword(getAuth(), email, password)
      if (response) {

        context.commit('SET_USER', response.user)
        updateProfile(response.user, { displayName: name })
        // ////
        const db = getDatabase();
        const uid = response.user.uid;
        console.log(uid)

        set(ref(db, 'users/' + uid), {
          level: 0,
          role: 'user',
          isActive: true,
          name: name
        })
          .then(() => {
            console.log('Usuario registrado correctamente');
            return true
          })
          .catch(err => {
            console.error(err);
            return err
          })

      } else {
        throw new Error('Unable to register user')
      }
    },

    async updatePassword(context, { newPassword }) {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user !== null) {
        try {
          await updatePassword(user, newPassword);
          console.log('Contraseña cambiada exitosamente');
          // Puedes redirigir al usuario a otra página o mostrar un mensaje de éxito aquí
        } catch (error) {
          console.error('Error al cambiar la contraseña:', error.message);
          // Maneja el error de manera adecuada, por ejemplo, mostrando un mensaje de error al usuario
        }
      }
    },

    async getUsersList(context) {
      const db = getDatabase();
      const usersRef = ref(db, 'users/');
      onValue(usersRef, (snapshot) => {
        const data = snapshot.val();
        // console.log('[GET user LIST] data -> ', data)
        let userList:any = []
        for (const uid in data) {
          if( data[uid].role === 'admin'){
            continue
          }
          const user = {
            uid: uid,
            name: data[uid].name,
          }
          userList.push(user)
        }
        userList = userList.sort((a, b) => {
          const nombreA = a.name.toUpperCase(); // Convertir a mayúsculas para ordenar de manera insensible a mayúsculas/minúsculas
          const nombreB = b.name.toUpperCase();
          
          if (nombreA < nombreB) {
            return -1; // a debe estar antes que b
          }
          if (nombreA > nombreB) {
            return 1; // b debe estar antes que a
          }
          return 0; // a y b son iguales
        });

        if (!data) {
          return []
        } else {
          // console.log(userList)
          context.commit('SET_USERS_DATA',userList)
          return data;
        }
      });
    },
    async getUserData(context, uid) {
      console.log(uid)
      const db = getDatabase();
      const usersRef = ref(db, 'users/' + uid);

      const snapshot = await get(usersRef);
      const data = snapshot.val();
      data.level = data.level.toString()
        if (!data) {
          return []
        } else {
          context.commit('SET_USER_DETAIL',data)
          return data
        }
    },

    async updateUserDataByAdmin(context, { uid, level, isActive }) {
      const db = getDatabase();
      return update(ref(db, 'users/' + uid), {
        level: level,
        isActive: isActive
      })
        .then(() => {
          console.log('Codigo de registro actualizado en DB');
          return true
        })
        .catch(err => {
          console.error(err);
          return err
        })
    },

    async logIn(context, { email, password }) {
      await signInWithEmailAndPassword(getAuth(), email, password)
        .then(async (data) => {
          
          console.log('Successfully logged in!');
          context.commit('SET_USER', data.user);
          context.commit('SET_LOGGED_IN', true);
          await store.dispatch('getLevel');
          store.dispatch('activeValidation');
          store.dispatch('getRole');

        })
        .catch(error => {
          console.error(error)
          switch (error.code) {
            case 'auth/invalid-email':
              throw new Error('Invalid email')
            case 'auth/user-not-found':
              throw new Error('No account with that email was found')
            case 'auth/wrong-password':
              throw new Error('Incorrect password')
            default:
              throw new Error('Email or password was incorrect')
          }
        });
    },

    async logOut(context) {
      await signOut(getAuth())
      context.commit('SET_USER', null)
    },

    async fetchUser(context, user) {
      console.warn('user ->', user)
      context.commit("SET_LOGGED_IN", user !== null);
      if (user) {
        context.commit("SET_USER", {
          displayName: user.displayName,
          email: user.email
        });
        return true
      } else {
        context.commit("SET_USER", null);
        return false
      }
    },
    async saveCombo(context, {tricks, comboName}) {
      console.log('entre')
      const db = getDatabase();
      const uid = getAuth().currentUser?.uid;

      const combosRef = ref(db, 'users/' + uid + '/combos/');
      onValue(combosRef, (snapshot) => {
        const data = snapshot.val();
        console.log('DATA _>', data)
        if (data) {
          context.commit("SET_COMBOS", data.length)
        }
      });
      // data que se envia no puede ser null ni vacia
      return set(ref(db, 'users/' + uid + '/combos/' + store.getters.getCombos), {
        comboId: store.getters.getCombos,
        created_at: DateTime.fromISO(DateTime.now()).toString(),
        tricks: tricks,
        combo_name: comboName
      })
        .then(() => {
          console.log('Combo guardado');
          return true
        })
        .catch(err => {
          console.error(err);
          return err
        })
    },

    async saveComboInLab(context, tricks) {
      console.log('[ saveComboInLab ]')
      const db = getDatabase();
      const uid = getAuth().currentUser?.uid;

      const combosRef = ref(db, 'users/' + uid + '/lab/');
      onValue(combosRef, (snapshot) => {
        const data = snapshot.val();
        console.log('DATA _>', data)
        if (data) {
          context.commit("SET_LAB", data.length)
        }
      });
      // data que se envia no puede ser null ni vacia
      return set(ref(db, 'users/' + uid + '/lab/' + store.getters.getLab), {
        labId: store.getters.getLab,
        created_at: DateTime.fromISO(DateTime.now()).toString(),
        tricks: tricks
      })
        .then(() => {
          console.log('Trucos almacenados en laboratorio');
          return true
        })
        .catch(err => {
          console.error(err);
          return err
        })
    },

    async getComboList(context) {
      try {
        const db = getDatabase();
      const uid = getAuth().currentUser?.uid;
      const combosRef = ref(db, 'users/' + uid + '/combos/');

      const snapshot = await get(combosRef);
      const data = snapshot.val();
      console.log('[GET COMBO LIST] data -> ', data)
        if (!data) {
          context.commit('SET_MY_COMBOS', data)
          // console.log(1)
        } else {
          context.commit("SET_COMBOS", data.length)
          // Filter empleado para el caso en que el primer elemento del arreglo
          // viene en 0
          context.commit('SET_MY_COMBOS', data.filter(n => n))
          // console.log(2)
        }
      } catch (error) {
        console.error('Error getting ComboList:', error);
      }
      
    },

    async getBlacklist(context) {
      try {
        const db = getDatabase();
        const uid = getAuth().currentUser?.uid;
        const combosRef = ref(db, 'users/' + uid + '/blacklist/');
    
        const snapshot = await get(combosRef);
        const data = snapshot.val() as Record<string, BlacklistItem> | null; // Define el tipo de data
    
        console.log('[GET BLACKLIST] data -> ', data);
    
        // Filtrar solo los elementos que tengan permanentlyHidden en true
        const filteredData = Object.values(data || {}).filter(
          (item): item is BlacklistItem => item?.permanentlyHidden === true
        );
    
        // Actualizar el estado con los elementos filtrados
        context.commit('SET_BLACKLIST', filteredData);
      } catch (error) {
        console.error('Error getting blacklist:', error);
      }
    },

    async getLabList(context) {
      const db = getDatabase();
      const uid = getAuth().currentUser?.uid;
      const labRef = ref(db, 'users/' + uid + '/lab/');
      onValue(labRef, async (snapshot) => {
        const data = snapshot.val();
        console.log('[GET LAB LIST] data -> ', data)
        if (!data) {
          context.commit('SET_MY_LAB', data)
          // console.log(1)
        } else {
          context.commit("SET_LAB", data.length)
          // Filter empleado para el caso en que el primer elemento del arreglo
          // viene en 0
          context.commit('SET_MY_LAB', data.filter(n => n))
          // console.log(2)
        }
      });
    },


    async getLabById(context, id) {
      return store.getters.getMyLab[id - 1];
    },
    async getComboById(context, id) {
      return store.getters.getMyCombos[id - 1];
    },

    async getLevel(context) {
      try {
        const db = getDatabase();
        const uid = store.getters.getUid;
        const levelRef = ref(db, 'users/' + uid);

        const snapshot = await get(levelRef);
        const level = snapshot.val().level;

        console.log('LEVEL GET LEVEL -> ', level);
        context.commit('SET_LEVEL', level);
      } catch (error) {
        console.error('Error getting level:', error);
      }
    },

    async getRole(context) {
      const db = getDatabase();
      const uid = store.getters.getUid;
      const levelRef = ref(db, 'users/' + uid);
      onValue(levelRef, async (snapshot) => {
        const role = snapshot.val().role;
        context.commit('SET_ROLE', role)
      });
    },
    async activeValidation(context) {
      const db = getDatabase();
      const uid = store.getters.getUid;
      const levelRef = ref(db, 'users/' + uid);
      return onValue(levelRef, async (snapshot) => {
        const isActive = snapshot.val().isActive;
        context.commit('SET_IS_ACTIVE', isActive)
      });
    },

    async saveRegisterCode(context, newCode) {
      const db = getDatabase();
      return push(ref(db, 'codes/'), {
        code: newCode,
        created_at: DateTime.fromISO(DateTime.now()).toString()
      })
        .then(() => {
          console.log('Codigo de registro guardado en DB');
          return true
        })
        .catch(err => {
          console.error(err);
          return err
        })
    },

    async saveFeedback(context,{user, comment}) {
      const db = getDatabase();
      return push(ref(db, 'feedback/'), {
        user: user,
        comment: comment,
        created_at: DateTime.fromISO(DateTime.now()).toString()
      })
        .then(() => {
          console.log('Feedback guardado en DB');
          return true
        })
        .catch(err => {
          console.error(err);
          return err
        })
    },
    async saveTrick(context, { name, description, family, code, levelName }) {
      const db = getDatabase();
      return push(ref(db, 'tricks/'), {
        name: name,
        description: description,
        family: family,
        code: code,
        levelName: levelName,
        created_at: DateTime.fromISO(DateTime.now()).toString()
      })
        .then(() => {
          console.log('Truco guardado en DB');
          return true
        })
        .catch(err => {
          console.error(err);
          return err
        })
    },
    // TODO : leer desde bd los codigos de validaciøn para acceso a registro
    async getRegisterCodes(context) {
      console.log('entre')
      const db = getDatabase();
      console.log(db);
      return onValue(ref(db, '/codes/'), (snapshot) => {
        const codes = snapshot.val()
        console.log(codes)
        context.commit('SET_CODES', codes);
      },);
    },
  },

  // plugins: [
  //   (store) => {
  //     store.watch(
  //       (state) => state.isActive, // Observa el estado que deseas
  //       (newValue, oldValue) => {
  //         console.log('El valor del estado miEstado ha cambiado:', newValue);
  //         if (newValue === false) {
  //           console.log('inactivo')
  //         }
  //         // this.miFuncionEspecifica(newValue);
  //       }
  //     );
  //   }
  // ]


});


// export the store
export default store